<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-29 16:46:42
 * @LastEditTime: 2021-05-26 22:28:50
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="goverment">
    <SonTitle title="公司治理"></SonTitle>
    <div v-html="info.content"></div>
  </div>
</template>

<script>
export default {
  computed: {
    info () {
      return this.$store.state.IndexData.zhili || {} // 取出数据
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
</style>